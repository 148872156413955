import styled from 'styled-components'

export const LargeButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  width: 100%;
  height: 100%;
  cursor: pointer;
  overflow: hidden;
  position: relative;

  color: #a3b8c2;
  font-size: 1.6rem;
  font-style: normal;
  font-weight: normal;
  line-height: 100%;

  background-color: #ffffff;
  border-radius: 3px;
  box-shadow: 0 1px 10px rgba(0, 0, 0, 0.08);

  .side-bar {
    position: absolute;
    top: 0;
    right: -4.5rem;

    width: 4rem;
    height: 100%;
    cursor: pointer;
    border-radius: 0 3px 3px 0;
    background: #000000;

    .icon {
      display: flex;
      align-items: center;
      justify-content: center;

      cursor: pointer;
      position: absolute;
      top: 50%;
      left: -2.7rem;

      opacity: 0;
      width: 4rem;
      height: 4rem;
      border-radius: 3px;
      transform: translate(0%, -50%);
      color: #bdbdbd;
      background: #ffffff;
      box-shadow: 0 1px 10px rgba(0, 0, 0, 0.08);

      svg {
        width: 16px;
        height: 16px;
        path {
          fill: #bdbdbd;
        }
      }
    }
  }

  @media (min-width: 480px) {
    :hover {
      .side-bar {
        display: flex;
        align-items: center;

        right: 0;
        transition: 0.5s;

        .icon {
          display: flex;

          opacity: 1;
          left: -2.7rem;
        }
      }
    }
  }
`
