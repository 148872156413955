import { formatMoney, tr } from 'mmfintech-commons'
import { selectCurrentUserId, useAppSelector, useGetUserExpenseLimitsQuery } from 'mmfintech-backend-api'

import { ProgressBarWithInfoWrapper, ProgressBarInfoWrapper } from './SpendingLimitBar.styled'

export const SpendingLimitBarUser = () => {
  const currentUserId = useAppSelector(selectCurrentUserId)

  const { data: expenseLimits } = useGetUserExpenseLimitsQuery(
    { merchantUserId: currentUserId },
    {
      skip: !currentUserId
    }
  )

  const { availableMonthlyVolume, monthlyVolume, currency, spentMonthlyVolume } = expenseLimits || {}

  const availableMonthlyVol = availableMonthlyVolume > 0 ? availableMonthlyVolume : 0
  const progressPercentage = monthlyVolume ? 100 - (availableMonthlyVol / monthlyVolume) * 100 : 100

  if (!expenseLimits) {
    return null
  }

  return (
    <div>
      <ProgressBarInfoWrapper>
        <div>
          <span className='info-text'>{tr('PORTAL.COMPONENTS.LIMITS_BAR.MONEY_SPENT', 'Money spent')}:</span>
          <span>{formatMoney(spentMonthlyVolume, currency)}</span>
        </div>
        <div>
          <span className='info-text'>{tr('PORTAL.COMPONENTS.LIMITS_BAR.MONTHLY_LIMIT', 'Monthly limit')}:</span>
          {monthlyVolume ? <span>{formatMoney(monthlyVolume, currency)}</span> : <span className='infinity'>∞</span>}
        </div>
      </ProgressBarInfoWrapper>
      <ProgressBarWithInfoWrapper percentage={progressPercentage} monthlyVolume={monthlyVolume}>
        <div className='progress-bar'>
          <div>{monthlyVolume ? Math.round(progressPercentage) : 0}%</div>
        </div>
      </ProgressBarWithInfoWrapper>
    </div>
  )
}
