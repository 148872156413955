import { fixDateOnly, isValidArray, monthBackDate, todayDate } from 'mmfintech-commons'
import { useGetAggregatedBalanceQuery } from 'mmfintech-backend-api'

import { ErrorDisplay } from 'mmfintech-portal-commons'
import { GraphSection } from './GraphSection'
import { AccountsSection } from './AccountsSection'
import { OnboardingBanner, RecentTransactions } from '../../../components'

export const DashboardAdmin = () => {
  const { balance, error } = useGetAggregatedBalanceQuery(
    {
      dateFrom: '',
      dateTo: ''
    },
    {
      selectFromResult: ({ data, error }) => ({
        balance: isValidArray(data) ? data[data.length - 1] : null,
        error
      })
    }
  )

  return (
    <>
      <AccountsSection balance={balance} />
      <ErrorDisplay error={error} />

      <OnboardingBanner />
      <GraphSection />

      <RecentTransactions
        filter={{
          from: fixDateOnly(monthBackDate()),
          to: fixDateOnly(todayDate()),
          page: 0,
          size: 5,
          sort: 'reqTime-desc,id-desc'
        }}
      />
    </>
  )
}
