import { useMerchantAccounts, useMyPermissionsQry } from 'mmfintech-backend-api'

import { Preloader } from 'mmfintech-portal-commons'
import { AccountOverviewRow } from './AccountOverviewRow'

export const AccountOverviewList = () => {
  const { permissions, permissionsFetching } = useMyPermissionsQry()

  const { activeAccounts, accountsFetching } = useMerchantAccounts()

  if (accountsFetching || permissionsFetching) {
    return <Preloader />
  }

  const accounts = activeAccounts?.map((a: any) => {
    const permission = permissions?.find((perm: any) => a?.id === perm?.accountId)
    return { ...a, permission: permission?.accessLevel }
  })

  return (
    <div className='mb-4'>
      {accounts.map((account: any, i: number) => {
        return <AccountOverviewRow account={account} key={i} />
      })}
    </div>
  )
}
