import styled from 'styled-components'

export const VerificationContainer = styled.div`
  position: relative;
  padding: 3rem 2rem;

  @media (min-width: 360px) {
    padding: 3rem 5rem 4rem;
  }

  width: 100%;
  max-width: 50rem;

  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  margin: auto;

  background-color: white;
  border-radius: 8px;
  box-shadow: 0 0 25px rgba(46, 53, 56, 0.1);

  .err-msg-wrapper {
    margin: 0;
    display: flex;
    flex-direction: row;
    justify-content: center;
  }

  .title {
    color: black;
    font-size: 1.8rem;
    font-style: normal;
    font-weight: 400;
    line-height: 2.2rem;
    text-align: center;
  }

  .subtitle {
    color: black;
    font-size: 1.4rem;
    font-style: normal;
    font-weight: 400;
    line-height: 2.2rem;

    text-align: center;
    margin-top: 2rem;
    margin-bottom: 2rem;

    span {
      display: block;
      font-weight: 700;
      overflow-wrap: anywhere;
    }
  }

  .input {
    color: black;
    font-size: 1.8rem;
    font-style: normal;
    font-weight: 600;
    line-height: 2.2rem;
    letter-spacing: 1.2rem;

    text-align: center;

    width: 20rem;
    margin: 0 auto;
    padding-bottom: 1rem;

    border: none;
    border-bottom: 1px solid #e5e5e5;
    background-color: white;

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    &:focus {
      outline: none;
    }

    &::-webkit-input-placeholder {
      color: #dadada;
      font-family: Inter, sans-serif;
      font-size: 1.8rem;
      font-style: normal;
      font-weight: 400;
      line-height: 2.2rem;
      letter-spacing: normal;
    }
    &:-ms-input-placeholder {
      color: #dadada;
      font-family: Inter, sans-serif;
      font-size: 1.8rem;
      font-style: normal;
      font-weight: 400;
      line-height: 2.2rem;
      letter-spacing: normal;
    }
    &::placeholder {
      color: #dadada;
      font-family: Inter, sans-serif;
      font-size: 1.8rem;
      font-style: normal;
      font-weight: 400;
      line-height: 2.2rem;
      letter-spacing: normal;
    }

    &.wrong {
      border-color: #ff3131;
    }
  }
  .buttons-wrap {
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    justify-content: center;
    //margin-top: 3rem;

    .button {
      //max-width: 35rem;
      margin-top: 1rem;

      :first-child {
        margin-top: 3rem;
      }
    }
  }
`

export const ResendMessage = styled.div`
  color: black;
  font-size: 1.2rem;
  font-style: normal;
  font-weight: 500;
  line-height: 1.7rem;
  text-align: center;

  margin-top: 2rem;

  span {
    margin-top: 0.5rem;
    display: block;
    text-decoration: underline;
    cursor: pointer;
    color: #2f80ed;

    &.disabled {
      color: #545454;
      cursor: default;
      text-decoration: none;
    }
  }
`

export const Countdown = styled.div<{ isExpired: boolean }>`
  display: inline-block;
  position: absolute;
  top: 1.5rem;
  right: 2rem;

  color: ${p => (p.isExpired ? '#ee2b2b' : '#939ea7')};
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.2rem;
`
