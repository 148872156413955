import styled from 'styled-components'

export const ActivityWrapper = styled.div`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;

  width: 100%;
  padding: 1.6rem 3rem;

  color: black;
  font-size: 1.4rem;
  font-style: normal;
  font-weight: normal;
  line-height: 1.7rem;

  @media (max-width: 1400px) {
    padding: 1.6rem 2rem;
  }

  .left-side {
    display: flex;
    flex-flow: row nowrap;
    align-items: flex-start;
  }

  .time {
    color: #a3b8c2;
    margin-right: 2rem;
  }

  .arrow {
    margin-left: 1rem;
    margin-right: -1rem;
    width: 2rem;

    img,
    svg {
      display: none;
    }
  }

  &:hover {
    color: white;
    background-color: black;

    .time {
      color: black;
    }
    transition: all 500ms;

    .arrow {
      img,
      svg {
        display: inline-block;
      }
    }
  }
`
