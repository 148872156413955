import cn from 'classnames'

import { formatPhoneNumber, tr, useValidUntilTimer } from 'mmfintech-commons'
import { globalSettings, selectCurrentUserStatus, useAppSelector, useOtpQry } from 'mmfintech-backend-api'

import { Button, ErrorDisplay, Spinner } from 'mmfintech-portal-commons'
import { Countdown, ResendMessage, VerificationContainer } from './Otp.styled'

import { LoginStatusEnum, TwoFactorTypeEnum } from 'mmfintech-commons-types'

export const Otp = () => {
  const {
    code,
    codeSize,
    attempts,
    challenge,
    handleCodeChanged,
    handleCancel,
    handleResend,
    verifyOtpError,
    verifyOtpFetching,
    resendOtpFetching,
    resendTimer
  } = useOtpQry({ autoSubmit: true })

  const timer = useValidUntilTimer()
  const userStatus = useAppSelector(selectCurrentUserStatus)

  const { sentTo, twoFactorType } = challenge || {}

  const prepareTitle = () => {
    switch (twoFactorType) {
      case TwoFactorTypeEnum.SMS:
        return userStatus === LoginStatusEnum.LOGGED_IN
          ? tr('PORTAL.OTP.TITLE_SMS_VERIFY', 'SMS verification')
          : tr('PORTAL.OTP.TITLE_SMS_CONFIRM', 'SMS confirmation')

      case TwoFactorTypeEnum.EMAIL:
        return userStatus === LoginStatusEnum.SECOND_FACTOR || userStatus === LoginStatusEnum.LOGGED_IN
          ? tr('PORTAL.OTP.TITLE_EMAIL_VERIFY', 'Email verification')
          : tr('PORTAL.OTP.TITLE_EMAIL_CONFIRM', 'Email confirmation')

      default:
        return userStatus === LoginStatusEnum.LOGGED_IN
          ? tr('PORTAL.OTP.TITLE_VERIFY', 'Verification')
          : tr('PORTAL.OTP.TITLE_CONFIRM', 'Confirmation')
    }
  }

  return (
    <VerificationContainer data-test='otp-verification-dialog'>
      <div className='title'>{prepareTitle()}</div>
      <div className='subtitle'>
        {tr('PORTAL.OTP.TARGET', "Please, enter the verification code that we've sent to:")}
        <span>{twoFactorType === TwoFactorTypeEnum.SMS ? formatPhoneNumber(sentTo) : sentTo}</span>
      </div>

      {verifyOtpFetching ? (
        <Spinner />
      ) : (
        <>
          <input
            id='otp'
            type='tel'
            className={`${cn({
              complete: code?.length === codeSize,
              wrong: code?.length === codeSize && verifyOtpError != null
            })} input`}
            autoComplete='off'
            onChange={e => handleCodeChanged(e.target.value)}
            value={code}
            maxLength={codeSize}
            autoFocus
            placeholder={tr('PORTAL.OTP.ENTER_CODE', 'Enter code')}
            disabled={timer.expired || attempts >= globalSettings.otpMaxAttempts}
            data-test='tel-input'
          />

          <ResendMessage>
            {attempts < globalSettings.otpMaxAttempts ? (
              <>
                {tr('PORTAL.OTP.NOT_RECEIVED', "Haven't received it?")}
                <span onClick={handleResend} className={cn({ disabled: !resendTimer.expired })}>
                  {resendTimer.expired ? tr('PORTAL.OTP.RESEND_CODE', 'Re-send code') : resendTimer.remainingTime}
                </span>
              </>
            ) : (
              tr('PORTAL.OTP.NO_MORE_ATTEMPTS', 'No more verification attempts.')
            )}
          </ResendMessage>

          <ErrorDisplay error={verifyOtpError} />

          <div className='buttons-wrap'>
            <Button
              type='button'
              color='secondary'
              text={tr('FRONTEND.BUTTONS.CANCEL', 'Cancel')}
              disabled={verifyOtpFetching}
              onClick={handleCancel}
              data-test='button-cancel'
            />
          </div>
        </>
      )}

      {attempts >= globalSettings.otpMaxAttempts || verifyOtpFetching || resendOtpFetching ? null : (
        <Countdown isExpired={timer.expired}>{timer.formattedTime}</Countdown>
      )}
    </VerificationContainer>
  )
}
