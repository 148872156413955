import styled from 'styled-components'

export const WarningMessageWrapper = styled.div`
  display: flex;
  flex-flow: column nowrap;
  align-items: flex-start;

  width: 100%;
  max-width: 50rem;
  margin-bottom: 2rem;

  padding: 2rem;
  @media (min-width: 762px) {
    padding: 2rem 3rem;
  }

  background: #ffffff;
  border-radius: 8px;
  box-shadow: 0 0 25px rgba(46, 53, 56, 0.1);

  color: black;
  font-size: 1.2rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5rem;

  p {
    margin: 0 0 1.5rem;
  }

  .label {
    margin-bottom: 2rem;
    text-align: center;
    width: 100%;

    display: flex;
    align-items: center;
    justify-items: flex-start;

    color: black;
    font-size: 1.6rem;
    font-style: normal;
    font-weight: 600;
    line-height: 2rem;

    img,
    svg {
      margin-right: 1rem;
    }
  }
`
