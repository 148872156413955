import { tr } from 'mmfintech-commons'
import { isOwnerOrAdministrator, selectCurrentUserRole, useAppSelector } from 'mmfintech-backend-api'

import { DashboardAdmin } from './DashboardAdmin'
import { DashboardStaff } from './DashboardStaff'
import { ActivitiesSection } from './Activities'
import {
  DashboardLeft,
  DashboardRight,
  DashboardRightTitle,
  DashboardTitle,
  DashboardWrapper
} from './Dashboard.styled'

export const Dashboard = () => {
  const customerRole = useAppSelector(selectCurrentUserRole)

  return (
    <DashboardWrapper>
      <DashboardLeft>
        <DashboardTitle className='title'>{tr('PORTAL.DASHBOARD.TITLE', 'Overview')}</DashboardTitle>
        {isOwnerOrAdministrator(customerRole) ? <DashboardAdmin /> : <DashboardStaff />}
      </DashboardLeft>

      <DashboardRight>
        <DashboardRightTitle>{tr('PORTAL.DASHBOARD.ACTIVITIES', 'Activity')}</DashboardRightTitle>
        <ActivitiesSection />
      </DashboardRight>
    </DashboardWrapper>
  )
}
