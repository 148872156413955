import React from 'react'
import { Link } from 'react-router-dom'
import { useDispatch } from 'react-redux'

import { tr, useDropDownMenu } from 'mmfintech-commons'
import { api, useLazyLogoutQuery } from 'mmfintech-backend-api'

import { DropDownMenuContainer, DropDownMenuIcon, DropDownMenuWrapper } from './UserDropDownMenu.styled'

export const UserDropDownMenu = ({ UserMenuItems }) => {
  const { visible, hideDropDown, toggleDropDown } = useDropDownMenu()

  const dispatch = useDispatch()

  const [logout] = useLazyLogoutQuery()

  const handleLogout = async (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault()
    hideDropDown()
    await logout(null).unwrap()
    dispatch(api.util.resetApiState())
  }

  return (
    <DropDownMenuWrapper>
      <DropDownMenuIcon onClick={toggleDropDown}>
        <span className='menu-icon' />
      </DropDownMenuIcon>

      {visible && (
        <DropDownMenuContainer>
          <UserMenuItems onClick={hideDropDown} className='menu-item selector' />
          <div className='menu-footer'>
            <Link to='#' className='menu-item logout' onClick={handleLogout}>
              {tr('PORTAL.HEADER.LOGOUT', 'Log out')}
            </Link>
          </div>
        </DropDownMenuContainer>
      )}
    </DropDownMenuWrapper>
  )
}
