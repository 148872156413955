import { useHistory } from 'react-router-dom'

import { tr } from 'mmfintech-commons'
import { paths, useMyPermissionsQry, useTransactionsFilterQry } from 'mmfintech-backend-api'

import { Button } from 'mmfintech-portal-commons'
import { AccountOverviewList } from './AccountOverviewList'
import { DashboardContainer, DashboardWrapper } from './DashboardStaff.styled'
import { RecentTransactions, SpendingLimitBarUser } from '../../../components'

import SendIcon from '../../../images/icons/send.svg?react'

export const DashboardStaff = () => {
  const { hasAnyWritePermission } = useMyPermissionsQry()

  useTransactionsFilterQry({ accountId: -1 })

  const history = useHistory()

  const handleSendMoneyClick = () => history.push(paths.banking.send())

  return (
    <DashboardWrapper>
      <DashboardContainer>
        <SpendingLimitBarUser />

        {hasAnyWritePermission && (
          <Button
            type='button'
            color='secondary'
            icon={<SendIcon />}
            text={tr('PORTAL.DASHBOARD.SEND_BUTTON', 'Send')}
            onClick={handleSendMoneyClick}
            data-test='send-money-button'
          />
        )}
      </DashboardContainer>

      <AccountOverviewList />
      <RecentTransactions />
    </DashboardWrapper>
  )
}
