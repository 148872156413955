import styled from 'styled-components'
import { CommonPageTitle } from '../../components/guardianStyled'

export const TitleContainer = styled(CommonPageTitle)`
  margin-bottom: 2rem;
`

export const AccountsWrapper = styled.div`
  grid-area: main-content;
  width: 92vw;
  max-width: 140rem;
  padding-top: 5rem;
  padding-bottom: 3rem;
  margin: 0 auto;
`

export const AccountsHeader = styled.div`
  width: 100%;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;

  margin-bottom: 2rem;

  @media (min-width: 640px) {
    flex-flow: column nowrap;
    align-items: flex-start;
  }
  @media (min-width: 1024px) {
    flex-flow: row nowrap;
    align-items: center;
    justify-content: space-between;
  }

  .left-side,
  .right-side {
    .button {
      height: 4.6rem;
      border-radius: 6px;
      box-shadow: 0 0 25px rgba(46, 53, 56, 0.1);
      padding: 1rem 2rem 1rem 1.5rem;

      color: #85a0ad;
      font-size: 1.4rem;
      font-weight: 400;
      line-height: 1.7rem;
      white-space: nowrap;

      @media (min-width: 640px) {
        justify-content: flex-start;
        width: auto;
        min-width: 14rem;
      }
    }
  }

  .left-side {
    width: 100%;
    display: inline-flex;
    flex-flow: row nowrap;
    gap: 2rem;
    align-items: center;
    margin-bottom: 2rem;

    @media (min-width: 640px) {
      width: auto;
      flex-flow: row nowrap;
    }
    @media (min-width: 1024px) {
      margin-bottom: 0;
    }

    .select-wrapper {
      width: auto;
      min-width: 16rem;
    }
  }

  .right-side {
    display: flex;
    flex-flow: column nowrap;
    gap: 2rem;
    width: 100%;

    flex: 0;
    @media (min-width: 768px) {
      flex-flow: row nowrap;
    }
  }
`

export const AccountsContainer = styled.div`
  background-color: #ffffff;
  border-radius: 6px;
  display: flex;
  flex-flow: column nowrap;

  .data-table-cell,
  .data-table-head-cell {
    padding: 0 0.5rem;

    font-size: 1.4rem;
    font-weight: 400;
    line-height: 120%;
  }

  .accounts-pagination {
    padding: 2rem 0;
  }

  .edit-cell {
    display: none;
  }

  .data-table-container {
    border-radius: 6px;

    .data-table {
      border-radius: 1rem;
      .data-table-head {
        .data-table-row {
          .data-table-head-cell {
            height: 5rem;
            padding: 0;
          }
          :first-child {
            .data-table-head-cell {
              :first-child {
                border-top-left-radius: 6px;
              }
              :last-child {
                border-top-right-radius: 6px;
              }
            }
          }
        }
      }

      .data-table-body {
        border: none;

        .data-table-row {
          border: none;
          border-radius: 6px;
          box-shadow: 0 0 25px rgba(46, 53, 56, 0.1);
        }
      }

      @media (min-width: 768px) {
        .data-table-head {
          border: none;

          .data-table-row {
            border: none;
            border-bottom: 1px solid rgba(163, 184, 194, 0.3);

            .data-table-head-cell {
              :first-child {
                padding-left: 2rem;
              }
              :last-child {
                padding-right: 2rem;
              }
            }
          }
        }

        .data-table-body {
          .data-table-row {
            box-shadow: none;
            border-bottom: 1px solid #f4f6f6;

            .edit-cell {
              display: table-cell;
              span {
                width: 15px;
                height: 15px;
                display: inline-flex;
                svg {
                  display: none;
                }
              }
            }
            :hover {
              .edit-cell span svg {
                display: inline-block;
              }
            }

            .data-table-cell {
              height: 50px;
              padding: 0 0.5rem 0 0;

              :first-child {
                padding-left: 2rem;
              }
              :last-child {
                padding-right: 2rem;
              }
            }
          }
        }
      }
      @media (min-width: 1200px) {
        .data-table-head {
          .data-table-row {
            .data-table-head-cell {
              :first-child {
                padding-left: 4rem;
              }
              :last-child {
                padding-right: 4rem;
              }
            }
          }
        }
        .data-table-body {
          .data-table-row {
            .data-table-cell {
              :first-child {
                padding-left: 4rem;
              }
              :last-child {
                padding-right: 4rem;
              }
            }
          }
        }
      }
    }
  }

  .currency-icon {
    @media (max-width: 768px) {
      margin: 0 1rem 1rem 0;
    }
  }

  .transactions-cell {
    width: 100%;
    justify-content: center;
    .button {
      font-size: 1.6rem;
      font-weight: normal;
      width: 100%;
      max-width: 24rem;
      margin: 0 auto;
    }
  }

  @media (min-width: 768px) {
    box-shadow: 0 0 25px rgba(46, 53, 56, 0.1);
    .data-table-body {
      .data-table-row {
        box-shadow: none;
        cursor: pointer;
        .data-table-cell.small {
          width: 15%;
        }
      }
    }

    .transactions-cell {
      display: none;
    }
  }

  .accounts-pagination {
    .prev-button,
    .next-button,
    .page-button {
      background: #1a1a1a;
    }
  }
`

export const CurrencyWrapper = styled.span`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
`

export const CurrenciesSelectStyled = {
  menu: (provided: any) => ({
    ...provided,
    border: 'none !important',
    backgroundColor: '#ffffff'
  }),
  placeholder: (provided: any) => ({
    ...provided,
    color: 'black',
    marginLeft: '2.2rem',
    fontSize: '1.4rem',
    textTransform: 'capitalize'
  }),
  option: (provided: any, { isFocused }) => ({
    ...provided,
    fontSize: '1.4rem',
    fontWeight: '500',
    cursor: 'pointer',
    lineHeight: '2rem',
    color: isFocused ? '#ffffff' : '#000000',
    backgroundColor: isFocused ? '#000000' : 'transparent'
  }),
  container: (provided: any) => ({
    ...provided,
    height: '4.6rem',
    borderRadius: '9.6rem',
    border: 'none !important',
    borderColor: 'transparent',
    boxShadow: 'none',
    backgroundColor: 'transparent'
  }),
  control: (provided: any) => ({
    ...provided,
    width: '100%',
    height: '4.6rem',
    cursor: 'pointer',
    minHeight: 'auto',
    fontWeight: '600',
    textAlign: 'center',
    border: 'none !important',
    backgroundColor: '#ffffff',
    boxShadow: '0 0 25px rgb(46 53 56 / 10%)'
  }),
  singleValue: (provided: any) => ({
    ...provided,
    fontSize: '1.4rem',
    fontWeight: '600',
    lineHeight: '2rem',
    marginLeft: '0',
    width: '100%',
    color: '#000000'
  }),
  dropdownIndicator: (provided: any) => ({
    ...provided,
    cursor: 'pointer'
  })
}
