import styled from 'styled-components'

import { formatMoney, isValidArray } from 'mmfintech-commons'
import { selectMerchantCurrency, useAppSelector, useGraphQry } from 'mmfintech-backend-api'

import { Line } from 'react-chartjs-2'

export const DashboardChart = () => {
  const merchantCurrency = useAppSelector(selectMerchantCurrency)
  const { chartData, chartLabels, isEmptyBalance } = useGraphQry()

  const prepareData = () => ({
    labels: chartLabels,
    datasets: [
      {
        data: chartData,
        fill: true,
        backgroundColor: (context: any) => {
          const chart = context.chart
          const { ctx, chartArea } = chart
          if (!chartArea) {
            return
          }
          const gradient = ctx.createLinearGradient(0, chartArea.bottom, 0, chartArea.top)
          gradient.addColorStop(0, 'rgba(235, 248, 230, 0.3)')
          gradient.addColorStop(1, 'rgba(235, 248, 230, 0.8)')
          return gradient
        },
        borderColor: '#44CC00',
        borderWidth: 2,
        pointBackgroundColor: '#FFFFFF',
        pointBorderColor: '#44CC00',
        pointBorderWidth: 3,
        pointRadius: 4,
        hoverBackgroundColor: '#FFFFFF',
        hoverBorderColor: '#44CC00',
        hoverBorderWidth: 2
      }
    ]
  })

  // noinspection JSUnusedGlobalSymbols
  const options = {
    maintainAspectRatio: false,
    scales: {
      x: {
        ticks: {
          color: '#85A0AD',
          font: {
            family: 'Inter',
            size: 12,
            weight: 400,
            lineHeight: '15px'
          }
        },
        grid: {
          display: false
        }
      },
      y: {
        max: isEmptyBalance() ? 5 : null,
        min: isEmptyBalance() ? 0 : null,
        ticks: {
          color: '#85A0AD',
          font: {
            family: 'Inter',
            size: 12,
            weight: 400,
            lineHeight: '15px'
          },
          callback: function (value: number, index: number) {
            if (index === 0 || index % 2 === 0) {
              return formatMoney(value, merchantCurrency)
            }
          }
        },
        grid: {
          borderDash: [3, 4]
        }
      }
    },
    plugins: {
      tooltip: {
        displayColors: false,
        position: 'average',
        caretPadding: 10,
        backgroundColor: '#FFFFFF',
        bodyColor: '#000000',
        padding: 15,
        bodyAlign: 'left',
        bodyFont: {
          family: 'Inter',
          weight: 400,
          size: 16,
          lineHeight: '19px'
        },
        titleColor: '#000000',
        titleFont: {
          family: 'Inter',
          weight: 700,
          size: 10,
          lineHeight: '12px'
        },
        callbacks: {
          title: () => merchantCurrency,
          label: (context: any) => {
            if (context.raw === 0) return context.raw + ''

            if (context.raw % 1 === 0) {
              return context.raw + '.00'
            } else {
              if ((context.raw + '').split('.')[1].length === 1) {
                return context.raw + '0'
              } else {
                return context.raw
              }
            }
          }
        }
      },
      legend: {
        display: false
      }
    }
  }

  if (isValidArray(chartData)) {
    // noinspection JSValidateTypes
    return (
      <ChartWrapper>
        <Line data={prepareData} options={options as unknown} data-test='balance-chart' />
      </ChartWrapper>
    )
  }

  return null
}

const ChartWrapper = styled.div`
  height: 100%;

  canvas {
    width: 100%;
    max-width: 100%;
  }
`
