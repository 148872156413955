import styled from 'styled-components'
import { CommonSignupContainer, CommonSignupContent, CommonSignupWrapper } from '../../../components/guardianStyled'

export const LoginWrapper = styled(CommonSignupWrapper)`
  padding: 3rem 0 10rem;
`

export const LoginContainer = styled(CommonSignupContainer)``

export const LoginContentWrapper = styled.div`
  width: 100%;
`

export const LoginContent = styled(CommonSignupContent)``

export const StyledForm = styled.form`
  width: 100%;

  display: flex;
  flex-direction: column;

  .input-wrapper,
  .button {
    width: 100%;
  }

  .link-wrap {
    margin: 0.5rem 0 2.4rem;
    text-align: right;

    a {
      color: rgba(0, 97, 232, 0.6);
      font-size: 1.2rem;
      font-weight: 400;
      line-height: 102.52%;
      text-decoration: none;
      outline: none;
    }
  }
`

export const SignupLinkWrapper = styled.div`
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: center;

  color: rgba(255, 255, 255, 0.6);
  font-size: 1.3rem;
  font-style: normal;
  font-weight: 400;
  line-height: 102.52%;
  letter-spacing: -0.025em;

  a {
    border: 1px solid rgba(255, 255, 255, 0.2);
    border-radius: 4px;

    color: #ffffff;
    font-size: 1.3rem;
    font-weight: 600;
    line-height: 1.6rem;
    text-decoration: none;
    outline: none;

    display: flex;
    align-items: center;
    text-align: center;
    padding: 1.2rem 1.8rem;
    margin-left: 2.5rem;
  }
`
