import cn from 'classnames'

import { useExchangePairsQry } from 'mmfintech-backend-api'

import { CurrencyIcon } from '../../../../components'
import { DashboardChart } from './GraphChart'
import { ExchangeButtonWrap, ExchangePaneWrapper, GraphPaneWrapper } from './GraphSection.styled'

export const GraphSection = () => {
  const { updatedFxRates } = useExchangePairsQry({
    fixedRates: null
  })

  return (
    <GraphPaneWrapper>
      <div className='graph'>
        <DashboardChart />
      </div>

      <ExchangePaneWrapper>
        {updatedFxRates
          ?.filter((_node, index) => index < 4)
          .map((node, index) => <ExchangeButton key={index} node={node} />)}
      </ExchangePaneWrapper>
    </GraphPaneWrapper>
  )
}

const ExchangeButton = ({ node }) => {
  const { fromCurrency, toCurrency, rate, change } = node
  const { changePercentage } = change || {}

  return (
    <ExchangeButtonWrap>
      <div className='left'>
        <CurrencyIcon currency={fromCurrency} size='4rem' />
        <div className='rate'>
          <span className='currency'>
            {fromCurrency}/{toCurrency}
          </span>
          <span className='price'>{rate}</span>
        </div>
      </div>
      <span className={cn('change', { positive: changePercentage > 0, negative: changePercentage < 0 })}>
        {changePercentage}%
      </span>
    </ExchangeButtonWrap>
  )
}
