import { useEffect, useState } from 'react'

import { isValidArray } from 'mmfintech-commons'
import { useGetLastMerchantActivitiesQuery } from 'mmfintech-backend-api'

import { ErrorDisplay } from 'mmfintech-portal-commons'
import { ActivityDateLine } from './ActivityDateLine'
import { ActivityInfoLine } from './ActivityInfoLine'

export const ActivitiesSection = () => {
  const [activities, setActivities] = useState([])

  const { data, error, isFetching } = useGetLastMerchantActivitiesQuery()

  useEffect(() => {
    const list = []
    if (data) {
      data.forEach(item => {
        const dt = new Date(item.time).toDateString()
        let innerList = list.find(v => v.date === dt)
        if (innerList == null) {
          innerList = { date: dt, list: [item] }
          list.push(innerList)
        } else {
          innerList.list.push(item)
        }
      })
    }
    setActivities(list)
  }, [data])

  if (isFetching) return null

  if (error) {
    return <ErrorDisplay error={error} />
  }

  if (isValidArray(activities)) {
    return (
      <>
        {activities.map((item, index) => (
          <span className='line-wrap' key={`dt-${index}`}>
            <ActivityDateLine datum={item.date} />
            {item.list.map((activity: any, index2: number) => (
              <ActivityInfoLine key={`act-${index}-${index2}`} activity={activity} />
            ))}
          </span>
        ))}
      </>
    )
  }

  return null
}
