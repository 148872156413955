import cn from 'classnames'
import styled from 'styled-components'

import settings from '../settings'

import LogoImage from '../images/guardian-logo.png'

export const GuardianLogo = ({ autoWidth = false }) => (
  <LogoWrapper className={cn({ 'auto-width': autoWidth })}>
    <a href={settings.landingPageUrl}>
      <img src={LogoImage} alt='Logo' />
    </a>
  </LogoWrapper>
)

const LogoWrapper = styled.div`
  width: 92vw;
  max-width: 480px;
  margin-bottom: 3rem;

  @media (min-width: 640px) {
    width: auto;
  }

  &.auto-width {
    width: auto;
  }

  img {
    max-height: 56px;
  }
`
