import styled from 'styled-components'

import { useCurrencies } from 'mmfintech-backend-api'
import { formatMoney, tr } from 'mmfintech-commons'

import { CurrencyIcon } from '../../../components'

interface AccountOverviewRowProps {
  account: {
    id: number
    currencyCode: string
    balance: number
    name: string
    permission?: string
  }
}

export const AccountOverviewRow = ({ account }: AccountOverviewRowProps) => {
  const { currencyCode, balance, name, permission } = account

  const { getCurrencyPrecision } = useCurrencies()

  return (
    <AccountOverviewRowWrapper>
      <div className='cell'>
        <CurrencyIcon currency={currencyCode} size='3.4rem' className='mr-1 currency-icon' />
      </div>
      <div className='cell'>
        <span className='title'>{tr('PORTAL.DASHBOARD.ACCOUNT_OVERVIEW.CURRENCY', 'Currency')}</span>
        <span>{currencyCode}</span>
      </div>
      <div className='cell'>
        <span className='title'>{tr('PORTAL.DASHBOARD.ACCOUNT_OVERVIEW.ACCOUNT_NAME', 'Account name')}</span>
        <span className='subtitle'>{name}</span>
      </div>
      <div className='cell'>
        <span className='title'>{tr('PORTAL.DASHBOARD.ACCOUNT_OVERVIEW.PERMISSIONS', 'Permissions')}</span>
        <span className='subtitle'>
          {permission === 'WRITER'
            ? tr('PORTAL.DASHBOARD.ACCOUNT_OVERVIEW.FULL_ACCESS', 'Full')
            : tr('PORTAL.DASHBOARD.ACCOUNT_OVERVIEW.VIEW_ONLY', 'View only')}
        </span>
      </div>
      <div className='cell'>
        <span className='title'>{tr('PORTAL.DASHBOARD.ACCOUNT_OVERVIEW.BALANCE', 'Balance')}</span>
        <span className='subtitle'>
          {formatMoney(balance || 0, currencyCode || '', getCurrencyPrecision(currencyCode))}
        </span>
      </div>
    </AccountOverviewRowWrapper>
  )
}

const AccountOverviewRowWrapper = styled.div`
  display: grid;
  grid-template-columns: 5rem 1fr 1fr 1fr 1fr;
  gap: 1rem;
  font-size: 1.4rem;
  background: #ffffff;

  border-radius: 4px;
  padding: 1.5rem;
  margin-bottom: 1rem;

  .cell {
    display: grid;

    .title {
      font-weight: 400;
      font-size: 1rem;
      line-height: 1.5rem;
      color: #85a0ad;

      @media (min-width: 500px) {
        font-size: 1.2rem;
      }
    }
    .subtitle {
      font-weight: 400;
      font-size: 1.2rem;
      line-height: 1.7rem;

      @media (min-width: 500px) {
        font-size: 1.4rem;
      }
    }
  }
`
