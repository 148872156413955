import styled from 'styled-components'

export const ExpenseRequestModalWrapper = styled.div`
  width: 100%;
  min-width: 50rem;
  text-align: center;

  @media (max-width: 565px) {
    max-width: 35rem;
    min-width: 35rem;
    padding: 1rem;
  }

  @media (min-width: 560px) {
    padding: 4rem 5rem 5rem;
  }

  h3 {
    font-weight: 400;
    font-size: 2.4rem;
    line-height: 2.9rem;
    margin: 0;
  }

  p {
    font-size: 1.2rem;
    line-height: 1.8rem;
    margin-bottom: 3rem;
  }

  button:first-of-type {
    margin-bottom: 1rem;
  }
`
