import styled from 'styled-components'

export const PaymentMethodButton = styled.div`
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;

  border: 1px solid rgba(133, 160, 173, 0.3);
  border-radius: 4px;

  flex: 1;
  padding: 1rem;

  cursor: pointer;

  .left {
    font-size: 1.4rem;
    line-height: 1.7rem;

    display: flex;
    align-items: center;
    gap: 1.2rem;
  }
`

export const PaymentStep = styled.div`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: flex-start;
  gap: 2rem;

  width: 100%;

  font-size: 1.4rem;
  line-height: 1.7rem;
`

export const ApplicationFeeDetailsWrapper = styled.div`
  width: 92vw;
  max-width: 50rem;

  background-color: white;
  border-radius: 8px;
  box-shadow: 0 0 25px rgba(46, 53, 56, 0.1);

  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  gap: 3rem;

  padding: 3rem 2rem;

  @media (min-width: 940px) {
    padding: 4rem 5rem;
  }

  .title {
    font-size: 1.6rem;
    font-weight: 400;
    line-height: 1.9rem;
    text-align: center;
  }

  .subtitle {
    color: #85a0ad;
    font-size: 1.2rem;
    line-height: 1.5rem;
  }

  .buttons-container {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    width: 100%;
    gap: 2rem;
  }
`
