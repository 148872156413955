import styled from 'styled-components'

export const StatusMessageWrapper = styled.form`
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 0 25px rgba(46, 53, 56, 0.1);

  color: black;
  font-size: 1.6rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.9rem;

  width: 92vw;
  max-width: 50rem;

  padding: 2rem;
  @media (min-width: 768px) {
    padding: 4rem 5rem 2rem;
  }

  .change-2fa-submit-button {
    margin-top: 0 !important;
  }
  .change-2fa-phone-input-wrapper {
    margin-top: 2rem;

    .country-list {
      position: fixed;
    }
  }
  .password-terms,
  .radio-group {
    margin-bottom: 0;
  }

  .button {
    margin-top: 1rem;

    :first-of-type {
      margin-top: 4rem;
    }
  }
`

export const StatusMessageTitle = styled.div`
  color: black;
  font-size: 2.4rem;
  font-style: normal;
  font-weight: 400;
  line-height: 2.9rem;
  text-align: center;
  margin-bottom: 2rem;
`
