import { useEffect } from 'react'

import { fetchLastChunk, formatDate, formatMoney, isValidArray, tr, usePaginationQuery } from 'mmfintech-commons'
import {
  formatTransactionDescription,
  getTransactionStatusName,
  useCurrencies,
  useLazyGetTransactionsQuery
} from 'mmfintech-backend-api'

import { StatusBanner } from '../index.js'
import { TransactionsTableContainer, TransactionsWrapper } from './TransactionsTable.styled'
import { DataTable, NoElementsMessage, ErrorDisplay, Pagination } from 'mmfintech-portal-commons'

import { TransactionViewPortal } from 'mmfintech-commons-types'

export const TransactionsTable = ({ filter, showPagination, forceReload }) => {
  const { getCurrencyPrecision } = useCurrencies()

  const [fetchTransactions, { transactions, transactionsError, transactionsFetching }] = useLazyGetTransactionsQuery({
    selectFromResult: ({ data, error, isFetching }) => ({
      transactions: isValidArray(data?.content) ? data.content : [],
      transactionsError: error,
      transactionsFetching: isFetching
    })
  })

  const pagination = usePaginationQuery({
    rowsPerPage: 5,
    reload: async (params = {}, onSuccess: (response: any) => void) => {
      const data = {
        ...filter,
        ...params
      }

      try {
        const response = await fetchTransactions(data).unwrap()
        if (response) {
          onSuccess(response)
        }
      } catch {}
    }
  })

  useEffect(() => {
    pagination.reload()
  }, [forceReload])

  return (
    <TransactionsWrapper>
      {transactionsFetching ? null : transactionsError ? (
        <ErrorDisplay error={transactionsError} />
      ) : (
        <TransactionsTableContainer>
          {isValidArray(transactions) ? (
            <>
              <DataTable.Table scale2='1000px' skipHover={true}>
                <DataTable.Head>
                  <DataTable.Row>
                    <DataTable.HeadCell>{tr('FRONTEND.TRANSACTIONS.COLUMNS.ID', 'ID')}</DataTable.HeadCell>
                    <DataTable.HeadCell>{tr('FRONTEND.TRANSACTIONS.COLUMNS.DATE', 'Date')}</DataTable.HeadCell>
                    <DataTable.HeadCell>{tr('FRONTEND.TRANSACTIONS.COLUMNS.TYPE', 'Type')}</DataTable.HeadCell>
                    <DataTable.HeadCell>
                      {tr('FRONTEND.TRANSACTIONS.COLUMNS.DESCRIPTION', 'Description')}
                    </DataTable.HeadCell>
                    <DataTable.HeadCell>{tr('FRONTEND.TRANSACTIONS.COLUMNS.STATUS', 'Status')}</DataTable.HeadCell>
                    <DataTable.HeadCell>{tr('FRONTEND.TRANSACTIONS.COLUMNS.AMOUNT', 'Amount')}</DataTable.HeadCell>
                  </DataTable.Row>
                </DataTable.Head>

                <DataTable.Body>
                  {transactions.map((data: TransactionViewPortal, index: number) => {
                    const {
                      id,
                      reqTime,
                      amount: transactionAmount,
                      currency: transactionCurrency,
                      direction,
                      status,
                      cardPaymentDetails,
                      trnTypeLocalizationKey
                    } = data
                    const { transactionAmount: cardPaymentAmount, transactionCurrency: cardPaymentCurrency } =
                      cardPaymentDetails || {}

                    const amount = cardPaymentAmount ? cardPaymentAmount : transactionAmount
                    const currency = cardPaymentAmount ? cardPaymentCurrency : transactionCurrency

                    return (
                      <DataTable.Row key={index} data-test='table-row'>
                        <DataTable.Cell caption={tr('FRONTEND.TRANSACTIONS.COLUMNS.ID', 'ID')} data-test='id'>
                          {id}
                        </DataTable.Cell>

                        <DataTable.Cell
                          caption={tr('FRONTEND.TRANSACTIONS.COLUMNS.DATE', 'Date')}
                          className='no-wrap'
                          data-test='date'>
                          {formatDate(reqTime)}
                        </DataTable.Cell>

                        <DataTable.Cell caption={tr('FRONTEND.TRANSACTIONS.COLUMNS.TYPE', 'Type')} data-test='type'>
                          {tr(trnTypeLocalizationKey, fetchLastChunk(trnTypeLocalizationKey))}
                        </DataTable.Cell>

                        <DataTable.Cell
                          caption={tr('FRONTEND.TRANSACTIONS.COLUMNS.DESCRIPTION', 'Description')}
                          data-test='description'>
                          {formatTransactionDescription(data)}
                        </DataTable.Cell>

                        <DataTable.Cell
                          caption={tr('FRONTEND.TRANSACTIONS.COLUMNS.STATUS', 'Status')}
                          className={'text-upper status-' + status.toLowerCase()}
                          data-test='status'>
                          <StatusBanner status={getTransactionStatusName(status)} />
                        </DataTable.Cell>

                        <DataTable.Cell
                          caption={tr('FRONTEND.TRANSACTIONS.COLUMNS.AMOUNT', 'Amount')}
                          data-test='amount'>
                          {formatMoney(
                            amount * (direction === 'WITHDRAW' ? -1 : 1) || 0,
                            currency || '',
                            getCurrencyPrecision(currency)
                          )}
                        </DataTable.Cell>
                      </DataTable.Row>
                    )
                  })}
                </DataTable.Body>
              </DataTable.Table>

              {showPagination && <Pagination {...pagination.register()} />}
            </>
          ) : (
            <NoElementsMessage
              message={tr('PORTAL.TRANSACTIONS.NO_TRANSACTIONS', "You don't have any transactions yet")}
            />
          )}
        </TransactionsTableContainer>
      )}
    </TransactionsWrapper>
  )
}
