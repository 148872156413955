import styled from 'styled-components'

export const AmountValueWrapper = styled.div`
  font-size: 1.6rem;
  line-height: 1.9rem;
  height: 65px;
  margin-bottom: 2rem;
  width: 100%;

  .amountTitle {
    margin-bottom: 0.5rem;
  }
`

export const FeeWrapper = styled.div`
  color: #678898;
  font-size: 1.2rem;
  font-style: italic;
  font-weight: normal;
  margin-top: 0.3rem;
`
