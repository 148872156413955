import { useHistory } from 'react-router-dom'

import parse from 'html-react-parser'

import { tr } from 'mmfintech-commons'
import {
  paths,
  useDepositInitiateMutation,
  useDepositPreviewMutation,
  useGetMerchantQuery
} from 'mmfintech-backend-api'

import { ErrorDisplay } from 'mmfintech-portal-commons'

import { DepositIn, PaymentMethodEnum } from 'mmfintech-commons-types'

import applicationFeeImage from '../../images/app-fee-no-funds-image.png'

import BankWebServiceIcon from '../../images/icons/bank-web-service-icon.svg?react'
import PaymentNetworkIcon from '../../images/icons/payment-network-icon.svg?react'
import ReferenceIcon from '../../images/icons/reference-icon.svg?react'
import TransferDetailsIcon from '../../images/icons/transfer-details-icon.svg?react'
import SepaIcon from '../../images/icons/sepa-icon.svg?react'
import SwiftIcon from '../../images/icons/swift-icon.svg?react'
import ArrowIcon from '../../images/icons/arrow-right-small-grey.svg?react'

import { ApplicationFeeDetailsWrapper, PaymentMethodButton, PaymentStep } from './ApplicationFeeDetailsModal.styled'

interface ApplicationFeeDetailsProps {
  amount: number
  currency: string
}

const paymentSteps = [
  {
    icon: <PaymentNetworkIcon />,
    text: tr(
      'FRONTEND.APPLICATION_FEE.DETAILS.STEP_1',
      'Choose the payment network of your preference - <b>SWIFT</b> or <b>SEPA</b>'
    )
  },
  {
    icon: <TransferDetailsIcon />,
    text: tr('FRONTEND.APPLICATION_FEE.DETAILS.STEP_2', 'Our bank transfer details will appear on the screen ')
  },
  {
    icon: <BankWebServiceIcon />,
    text: tr(
      'FRONTEND.APPLICATION_FEE.DETAILS.STEP_3',
      'Log into your bank’s web service (or go to your bank) to transfer the funds'
    )
  },
  {
    icon: <ReferenceIcon />,
    text: tr(
      'FRONTEND.APPLICATION_FEE.DETAILS.STEP_4',
      'Don’t forget to add the <b>reference</b>. It will help us process your deposit faster'
    )
  }
]

export const ApplicationFeeDetailsModal = ({ amount, currency }: ApplicationFeeDetailsProps) => {
  const { data: merchant } = useGetMerchantQuery()
  const { entityType } = merchant || {}

  const history = useHistory()

  const [depositPreview, { error: previewError, isLoading: previewFetching }] = useDepositPreviewMutation()
  const [depositInitiate, { error: initiateError, isLoading: initiateFetching }] = useDepositInitiateMutation()

  const initiatePayment = async (paymentMethod: string) => {
    try {
      const data = { amount, currency, paymentMethod }
      await depositPreview(data as unknown as DepositIn).unwrap()
      await depositInitiate(data as unknown as DepositIn).unwrap()

      history.push({
        pathname: paths.banking.depositPreview(),
        state: { paymentType: paymentMethod }
      })
    } catch {}
  }

  const handleSelectPaymentOption = (paymentMethod: string) => {
    if (merchant && currency && entityType !== 'INDIVIDUAL' && !previewFetching && !initiateFetching) {
      void initiatePayment(paymentMethod)
    }
  }

  return (
    <ApplicationFeeDetailsWrapper>
      <img src={applicationFeeImage} alt='application-fee' />

      <div className='title'>
        {tr(
          'FRONTEND.APPLICATION_FEE.DETAILS.TITLE',
          'The Guardian Bank won’t take money from your bank automatically'
        )}
      </div>
      <div className='subtitle'>
        {tr('FRONTEND.APPLICATION_FEE.DETAILS.SUBTITLE', 'You’ll need to do this bit yourself to pay by bank transfer')}
      </div>

      {paymentSteps.map(({ icon, text }, index) => (
        <PaymentStep key={index}>
          {icon}
          <div>{parse(text)}</div>
        </PaymentStep>
      ))}

      <ErrorDisplay error={[previewError, initiateError]} />

      <div className='buttons-container'>
        <PaymentMethodButton onClick={() => handleSelectPaymentOption(PaymentMethodEnum.SWIFT_BANKWIRE)}>
          <div className='left'>
            <SwiftIcon />
            <span>SWIFT</span>
          </div>
          <ArrowIcon />
        </PaymentMethodButton>
        <PaymentMethodButton onClick={() => handleSelectPaymentOption(PaymentMethodEnum.BANKWIRE)}>
          <div className='left'>
            <SepaIcon />
            <span>SEPA</span>
          </div>
          <ArrowIcon />
        </PaymentMethodButton>
      </div>
    </ApplicationFeeDetailsWrapper>
  )
}
