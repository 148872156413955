import React from 'react'

import { paths } from 'mmfintech-backend-api'

import Login from './views/auth/Login'
import Dashboard from './views/dashboard'

const LazySignup = React.lazy(() => import('./views/auth/Signup'))
const LazySumSub = React.lazy(() => import('./views/onboarding/SumSubVerification'))
const LazyDeposit = React.lazy(() => import('./views/banking/Deposit'))
const LazyExchange = React.lazy(() => import('./views/banking/Exchange'))
const LazyInvoices = React.lazy(() => import('./views/invoices/Invoices'))
const LazyWithdraw = React.lazy(() => import('./views/banking/SendMoney'))
const LazyEmailVerify = React.lazy(() => import('./views/auth/VerifyEmail'))
const LazyInvoiceEdit = React.lazy(() => import('./views/invoices/InvoiceEdit'))
const LazyTransactions = React.lazy(() => import('./views/transactions'))
const LazyInvoiceUpload = React.lazy(() => import('./views/invoices/InvoiceUploadEdit'))
const LazyTransactionStatus = React.lazy(() => import('./views/transactions/TransactionStatus'))

import { LoginStatusEnum } from 'mmfintech-commons-types'

// prettier-ignore
export const nonProtectedRoutes = [
  { path: paths.login(), component: Login },
  { path: paths.signUpViaInviteLink(':referralCode'), component: LazySignup },
  { path: paths.signUp(), component: LazySignup },
  { path: paths.checkEmail(), component: React.lazy(() => import('./views/auth/VerifyEmailMessage')) },
  { path: paths.passwordReset(), component: React.lazy(() => import('./views/auth/ResetPasswordEmail')) },
  { path: paths.forgotPassword(), component: React.lazy(() => import('./views/auth/ForgotPassword')) },
  { path: paths.handleEmailVerify(), component: LazyEmailVerify },
  { path: paths.changePasswordEmail(), component: React.lazy(() => import('./views/auth/ForgotPasswordEmail')) },
  { path: paths.handlePasswordReset(), component: React.lazy(() => import('./views/auth/ResetPassword')) },
  { path: paths.handleInvitationVerify(), component: LazyEmailVerify },
  { path: paths.handlePasswordResetInvited(), component: React.lazy(() => import('./views/auth/SetPassword')) },

  { path: paths.invoices.checkout(':magicToken'), component: React.lazy(() => import('./views/invoices/InvoiceCheckout')) },
  { path: paths.invoices.viewByToken(':magicToken'), component: React.lazy(() => import('./views/invoices/InvoicePreviewByToken')) },

  { path: paths.banking.finalStatus.fail(':method'), params: { isSuccess: false }, component: LazyTransactionStatus },
  { path: paths.banking.finalStatus.success(':method'), params: { isSuccess: true }, component: LazyTransactionStatus },
]

// prettier-ignore
export const protectedRoutes = [
  { path: paths.root(), exact: true, redirect: paths.dashboard() },

  { path: paths.dashboard(), component: Dashboard },
  { path: paths.profile(), component: React.lazy(() => import('./views/settings/Profile')) },
  { path: paths.security(), component: React.lazy(() => import('./views/settings/Security')) },
  { path: paths.developer(), component: React.lazy(() => import('./views/developer')) },
  { path: paths.invite(':link'), component: React.lazy(() => import('./views/auth/Invite')) },
  { path: paths.userManagement(), component: React.lazy(() => import('./views/users')) },
  { path: paths.referralProgram(), component: React.lazy(() => import('./views/referral')) },

  { path: paths.onboarding.documents(), exact: true, component: React.lazy(() => import('./views/onboarding/AdditionalDocuments')) },
  { path: paths.onboarding.selectType(), exact: true, component: React.lazy(() => import('./views/onboarding/SelectOnboardingType')) },
  { path: paths.onboarding.questionnaireKyb(), exact: true, component: React.lazy(() => import('./views/onboarding/QuestionnaireKYB')) },
  { path: paths.onboarding.questionnaireKybSuccess(), exact: true, component: React.lazy(() => import('./views/onboarding/QuestionnaireSuccess'))},
  { path: paths.onboarding.sumSub.start(':merchantType'), component: LazySumSub, exact: true },
  { path: paths.onboarding.sumSub.continue(), exact: true, component: LazySumSub },

  { path: paths.banking.accounts.list(), component: React.lazy(() => import('./views/accounts')) },

  { path: paths.banking.transactions.details(':transactionId'), component: React.lazy(() => import('./views/transactions/TransactionDetails')) },
  { path: paths.banking.transactions.listByAccountId(':accountId'), component: LazyTransactions },
  { path: paths.banking.transactions.list(), component: LazyTransactions },

  { path: paths.banking.depositPreview(), exact: true, component: React.lazy(() => import('./views/banking/ApplicationFeePreview')) },
  { path: paths.banking.depositByAccountId(':accountId'), component: LazyDeposit },
  { path: paths.banking.deposit(), component: LazyDeposit },
  { path: paths.banking.exchangeFromTo(':fromCurrency', ':toCurrency'), component: LazyExchange },
  { path: paths.banking.exchange(), component: LazyExchange },
  { path: paths.banking.sendCrypto(), exact: true, component: React.lazy(() => import('./views/banking/CryptoTransfer')) },
  { path: paths.banking.sendTransfer(), exact: true, component: React.lazy(() => import('./views/banking/InternalTransfer')) },
  { path: paths.banking.sendBankTransfer(), exact: true, component: React.lazy(() => import('./views/banking/BankTransfer')) },
  { path: paths.banking.sendDigitalWallet(), exact: true, component: React.lazy(() => import('./views/banking/WalletTransfer')) },
  { path: paths.banking.sendIntraAccountTransfer(), exact: true, component: React.lazy(() => import('./views/banking/IntraAccountTransfer')) },
  { path: paths.banking.sendTo(':recipientId'), component: LazyWithdraw },
  { path: paths.banking.send(), component: LazyWithdraw },

  { path: paths.banking.expenses(), component: React.lazy(() => import('./views/requests')) },

  { path: paths.recipients.details(':recipientId'), component: React.lazy(() => import('./views/recipients/RecipientDetails')) },
  { path: paths.recipients.list(), component: React.lazy(() => import('./views/recipients/Recipients')) },

  { path: paths.customerInvoices(':payerId'), component: React.lazy(() => import('./views/customers/CustomerInvoices')) },
  { path: paths.customers(), component: React.lazy(() => import('./views/customers/Customers')) },

  { path: paths.invoices.uploadEdit(':invoiceId'), component: LazyInvoiceUpload },
  { path: paths.invoices.upload(), component: LazyInvoiceUpload },
  { path: paths.invoices.addForCustomer(':customerId'), component: LazyInvoiceEdit },
  { path: paths.invoices.add(), component: LazyInvoiceEdit },
  { path: paths.invoices.send(':invoiceId'), component: React.lazy(() => import('./views/invoices/InvoiceSend')) },
  { path: paths.invoices.edit(':invoiceId'), component: LazyInvoiceEdit },
  { path: paths.invoices.listByStatus(':status'), component: LazyInvoices },
  { path: paths.invoices.list(), component: LazyInvoices },
]

export const getRedirectByUserStatus = (status: string): string => {
  switch (status) {
    case LoginStatusEnum.NOT_VERIFIED:
      return paths.checkEmail()
    case LoginStatusEnum.FORGOT_PASSWORD:
      return paths.changePasswordEmail()
    case LoginStatusEnum.RESET_PASSWORD:
      return paths.handlePasswordResetInvited()
    case LoginStatusEnum.PASSWORD_RESET:
      return paths.passwordReset()
    default:
      return paths.login()
  }
}
