import { useContext } from 'react'

import { GlobalContext, tr } from 'mmfintech-commons'

import { Button } from 'mmfintech-portal-commons'
import { SuccessContainer, SuccessSubtitle } from './ApplicationFeeSuccessModal.styled'

import SuccessImage from '../../images/icons/success.svg'

export const ApplicationFeeSuccessModal = () => {
  const { modalHide } = useContext(GlobalContext)

  return (
    <SuccessContainer data-test='application-fee-paid-success'>
      <img src={SuccessImage} alt='' />

      <SuccessSubtitle>
        {tr('FRONTEND.PAYMENT.SUCCESS', 'Your Application Fee has been paid successfully')}
      </SuccessSubtitle>

      <Button
        type='button'
        color='primary'
        text={tr('FRONTEND.BUTTONS.OK', 'Ok')}
        onClick={modalHide}
        data-test='button-ok'
      />
    </SuccessContainer>
  )
}
