import styled from 'styled-components'

import { tr } from 'mmfintech-commons'

import { TransactionsTable } from './TransactionsTable'

export const RecentTransactions = ({ title = '', filter = {}, showPagination = false, forceReload = false }) => (
  <div>
    <TransactionsSectionTitle>
      {title || tr('PORTAL.DASHBOARD.TRANSACTIONS', 'Recent transactions')}
    </TransactionsSectionTitle>
    <TransactionsTable filter={filter} showPagination={showPagination} forceReload={forceReload} />
  </div>
)

const TransactionsSectionTitle = styled.div`
  color: #85a0ad;
  font-size: 2.4rem;
  font-style: normal;
  font-weight: 600;
  line-height: 2.9rem;
  margin-bottom: 2rem;
`
