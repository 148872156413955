import styled from 'styled-components'

export const DashboardWrapper = styled.div``

export const DashboardContainer = styled.div`
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-between;

  margin-bottom: 2rem;

  @media (min-width: 700px) {
    flex-flow: row nowrap;
    align-items: center;
  }

  .button {
    height: 46px;
    border-radius: 4px;
    box-shadow: 0 0 25px rgba(46, 53, 56, 0.1);

    color: #85a0ad;
    font-size: 1.4rem;
    font-weight: 400;
    line-height: 1.7rem;
    text-transform: capitalize;
    padding: 0 3rem;

    @media (min-width: 700px) {
      max-width: 14rem;
    }
  }
`
