import React, { useContext } from 'react'
import { Link } from 'react-router-dom'

import { useAppDispatch } from '../../../hooks'
import { OtpContext, tr, useFormValues } from 'mmfintech-commons'
import { paths, useLoginReqMutation, loggedIn } from 'mmfintech-backend-api'

import { GuardianLogo } from '../../../components'
import { Button, ErrorDisplay, Input } from 'mmfintech-portal-commons'
import {
  LoginContainer,
  LoginContent,
  LoginContentWrapper,
  LoginWrapper,
  SignupLinkWrapper,
  StyledForm
} from './Login.styled'

export const Login = () => {
  const { setOtpOnSuccess } = useContext(OtpContext)

  const [login, { error: loginError, isLoading: loginFetching, reset: resetLoginReq }] = useLoginReqMutation()

  const dispatch = useAppDispatch()

  const formValues = useFormValues({
    email: { required: true, validation: 'email' },
    password: { required: true }
  })

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    setOtpOnSuccess(() => (response: any) => {
      dispatch(loggedIn(response))
    })
    if (formValues.areValid()) {
      try {
        await login(formValues.prepare()).unwrap()
      } catch (error) {}
    }
  }

  const handleResetError = () => {
    if (loginError) {
      resetLoginReq()
    }
  }

  return (
    <LoginWrapper>
      <LoginContainer>
        <GuardianLogo />

        <LoginContentWrapper>
          <LoginContent>
            <div className='heading'>{tr('PORTAL.LOGIN.TITLE', 'Sign in')}</div>

            <StyledForm noValidate onSubmit={handleSubmit}>
              <Input
                id='email'
                type='email'
                data-test='email'
                label={tr('PORTAL.LOGIN.EMAIL', 'Email')}
                {...formValues.registerInput('email', handleResetError)}
                disabled={loginFetching}
                hideErrorLine
                hideRequired
                autoFocus
              />

              <Input
                id='password'
                type='password'
                data-test='password'
                label={tr('PORTAL.LOGIN.PASSWORD', 'Password')}
                {...formValues.registerInput('password', handleResetError)}
                disabled={loginFetching}
                autoComplete='off'
                hideErrorLine
                hideRequired
              />

              <div className='link-wrap' data-test='forgot-password'>
                <Link to={paths.forgotPassword()}>{tr('PORTAL.LOGIN.FORGOT_PASSWORD', 'Forgot password?')}</Link>
              </div>

              <ErrorDisplay error={loginError} />

              <Button
                type='submit'
                color='alternative'
                text={tr('PORTAL.LOGIN.LOGIN_BUTTON', 'Log in')}
                loading={loginFetching}
                data-test='button-submit'
              />
            </StyledForm>
          </LoginContent>

          <SignupLinkWrapper>
            {tr('PORTAL.LOGIN.NO_ACCOUNT', "Don't have an account?")}{' '}
            <Link to={paths.signUp()} data-test='sign-up'>
              <span>{tr('PORTAL.LOGIN.SIGNUP_BUTTON', 'Sign up')} →</span>
            </Link>
          </SignupLinkWrapper>
        </LoginContentWrapper>

        <span />
      </LoginContainer>
    </LoginWrapper>
  )
}
