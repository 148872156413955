import { useContext } from 'react'

import parse from 'html-react-parser'
import domPurify from 'dompurify'

import { paths, useInvoicePreviewQry } from 'mmfintech-backend-api'
import { transformMerchantActivity, useActivitiesQry } from 'mmfintech-backend-api'

import { GlobalContext } from 'mmfintech-commons'
import { InvoicePreview } from '../../../components'
import { ActivityWrapper } from './ActivityInfoLine.styled'

import RightArrowIcon from '../../../images/icons/arrow-right-white-small.svg?react'

export const ActivityInfoLine = ({ activity }) => {
  const { modalHide, modalShow } = useContext(GlobalContext)

  const { handleActivityClick } = useActivitiesQry({
    securityPath: paths.security(),
    handleInvoicePreview: invoiceId => previewInvoice(invoiceId)
  })

  const { fetchPreviewText /*, invoicePreviewError, invoicePreviewFetching*/ } = useInvoicePreviewQry()

  const previewInvoice = (invoiceId: string | number) => {
    void fetchPreviewText(Number(invoiceId), content => {
      modalShow({
        options: { size: 'extra-large' },
        content: <InvoicePreview content={content} onClose={modalHide} />
      })
    })
  }

  const data = transformMerchantActivity(activity)
  const fixTime = (i: number) => (i < 10 ? '0' + i : i)

  const dt = new Date(activity?.time)
  const h = fixTime(dt.getHours())
  const m = fixTime(dt.getMinutes())
  const s = fixTime(dt.getSeconds())

  return (
    <ActivityWrapper className='activity-section' onClick={() => handleActivityClick(data)}>
      <span className='left-side'>
        <span className='time'>
          {h}:{m}:{s}
        </span>
        <span className='description'>{parse(domPurify.sanitize(activity?.text))}</span>
      </span>
      <span className='arrow'>
        <RightArrowIcon />
      </span>
    </ActivityWrapper>
  )
}
