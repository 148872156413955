import parse from 'html-react-parser'
import domPurify from 'dompurify'

import { tr } from 'mmfintech-commons'
import { getInvoiceStatusName } from 'mmfintech-backend-api'

import { CloseIcon, CloseIconRow, CloseIconWrap, PreviewContent, PreviewWrapper } from './InvoicePreview.styled'

interface InvoicePreviewProps {
  status?: string
  content?: string
  onClose: () => void
}

export const InvoicePreview = (props: InvoicePreviewProps) => {
  const { status, content, onClose } = props

  return (
    <PreviewWrapper data-test='invoice-preview-modal'>
      <CloseIconRow className={'status-' + status?.toLowerCase()}>
        <span>
          <span className='status-title'>{tr('PORTAL.INVOICES.PREVIEW.STATUS', 'Invoice status')}: </span>
          <span>{getInvoiceStatusName(status)}</span>
        </span>

        <CloseIconWrap>
          <CloseIcon onClick={onClose} />
        </CloseIconWrap>
      </CloseIconRow>

      <PreviewContent>{parse(domPurify.sanitize(content))}</PreviewContent>
    </PreviewWrapper>
  )
}
