import styled from 'styled-components'
import { Button } from 'mmfintech-portal-commons'

export const BannerWrapper = styled.div`
  border: 1px solid rgba(163, 184, 194, 0.5);
  border-radius: 4px;
  box-shadow: none;
  outline: none;

  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  text-align: center;

  margin-bottom: 2rem;
  padding: 1.5rem 1.5rem 1.5rem 2rem;
  width: 100%;

  font-size: 1.2rem;
  font-style: normal;
  font-weight: normal;
  line-height: 120%;

  @media (max-width: 900px) {
    display: flex;
    width: 100%;
  }

  .button {
    color: #ffffff;
    font-size: 1.4rem;
    font-weight: 500;
    line-height: 0;

    height: 4rem;
    padding: 0 3rem;
    margin-left: 2rem;
  }

  .icon {
    margin-right: 0;
    margin-bottom: 1rem;
  }

  .button {
    border-radius: 3px;
    font-size: 1.6rem;
    height: 1.2rem;
    margin-top: 1rem;
    margin-left: 0;
    padding: 2rem;
  }

  @media (min-width: 480px) {
    display: grid;
    grid-gap: 2rem;
    grid-template-columns: auto 1fr auto;
    text-align: left;

    .icon {
      margin-bottom: 0;
    }

    .button {
      margin-top: 0;
    }
  }
`

export const Centered = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`

export const TextBody = styled.div`
  display: flex;
  flex-direction: column;

  .onboarding-resume-header {
    font-size: 1.8rem;
    margin-bottom: 1rem;

    font-weight: 500;
  }

  &.centered {
    justify-content: center;
  }
`

export const LargeButton = styled(Button)`
  inline-size: max-content;
`
