import styled from 'styled-components'

export const CurrencyIconWrapper = styled.span<{ size: string }>`
  display: inline-block;
  width: ${({ size }) => size};
  height: ${({ size }) => size};
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
  border-radius: 8px;

  & > img {
    width: 100%;
    height: 100%;
    border-radius: 8px;
  }

  &.card {
    border: 1px solid #ffffff;
  }

  &.circle {
    border-radius: 50%;

    & > img {
      border-radius: 50%;
    }
  }
`
