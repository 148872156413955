import { useHistory } from 'react-router-dom'

import { extractCurrencyCode, formatMoney, tr } from 'mmfintech-commons'
import {
  isOwnerOrAdministrator,
  paths,
  selectCurrentUserRole,
  useAppSelector,
  useMyPermissionsQry
} from 'mmfintech-backend-api'

import { Button } from 'mmfintech-portal-commons'
import { AccountSectionWrapper, BalanceContainer } from './AccountsSection.styled'

import SendIcon from '../../../../images/icons/send.svg?react'
import DepositIcon from '../../../../images/icons/deposit.svg?react'
import ExchangeIcon from '../../../../images/icons/exchange.svg?react'

export const AccountsSection = ({ balance }) => {
  const customerRole = useAppSelector(selectCurrentUserRole)

  const history = useHistory()
  const { hasAnyWritePermission } = useMyPermissionsQry()

  const handleDepositClick = () => {
    history.push(paths.banking.deposit())
  }

  const handleSendMoneyClick = () => {
    history.push(paths.banking.send())
  }

  const handleExchangeClick = () => {
    history.push(paths.banking.exchange())
  }

  return (
    <AccountSectionWrapper>
      <BalanceContainer>
        <>
          <div className='label'>{tr('PORTAL.DASHBOARD.ACCOUNTS_TOTAL', 'Total account value')}</div>
          <div data-test='balance'>{formatMoney(balance?.amount, extractCurrencyCode(balance))}</div>
        </>
      </BalanceContainer>

      <div className='buttons-container'>
        {isOwnerOrAdministrator(customerRole) && (
          <Button
            type='button'
            color='secondary'
            icon={<DepositIcon />}
            text={tr('PORTAL.DASHBOARD.DEPOSIT_BUTTON', 'Deposit')}
            onClick={handleDepositClick}
            data-test='deposit-button'
            data-for='button-tooltip'
          />
        )}
        {hasAnyWritePermission && (
          <Button
            type='button'
            color='secondary'
            icon={<SendIcon />}
            text={tr('PORTAL.DASHBOARD.SEND_BUTTON', 'Send')}
            onClick={handleSendMoneyClick}
            data-test='send-money-button'
          />
        )}
        {isOwnerOrAdministrator(customerRole) && (
          <Button
            type='button'
            color='secondary'
            icon={<ExchangeIcon />}
            text={tr('PORTAL.DASHBOARD.EXCHANGE_BUTTON', 'Exchange')}
            onClick={handleExchangeClick}
            data-test='exchange-button'
            data-for='button-tooltip'
          />
        )}
      </div>
    </AccountSectionWrapper>
  )
}
