import brandLogo from './images/guardian-logo.png'

import backButtonIcon from './images/icons/back-arrow-grey.svg'
import backButtonIconHover from './images/icons/back-arrow-black.svg'
import backButtonIconSecondaryHover from './images/icons/back-arrow-white.svg'

import checkedIcon from './images/icons/checkbox-checked.svg'

import radioSelected from './images/icons/radio-selected.svg'
import radioUnselected from './images/icons/radio-unselected.svg'

const portalTheme = {
  brandLogo,
  fontName: 'Inter',
  
  containerBorderRadius: '3px',
  containerDropShadow: '0 1px 10px rgba(0, 0, 0, 0.08)',

  mainContentPadding: '0 2.5rem',
  mainContentPadding2: '0 2.5rem 4.5rem',

  titleColor: '#000000',

  header: {
    height: '9rem',
    heightThin: '9rem',
    templateColumns: '1fr minmax(32rem, 149rem) 1fr',
    gridColumn: '2 / 3',

    fontSize: '1.6rem',
    fontStyle: 'normal',
    fontWeight: '500',
    lineHeight: '1.9rem',
    letterSpacing: 'normal',
    textColor: '#ffffff',
    background: '#000000',
    boxShadow: '0 0 10px rgba(150, 150, 150, 0.5)',

    activeBorder: '3px solid #ff3131',
    activeTextColor: '#ffffff',
    activeFontWeight: '500',

    linkTextColor: '#ffffff',
    linkHoverColor: '#ffffff',

    signupBorderRadius: '0.3rem',
    signupBackground: '#ffffff',
    signupBackgroundHover: '#ffffff',
    signupTextColor: '#000000',
    signupFontSize: '1.5rem',
    signupFontWeight: '600',
    signupLineHeight: '1.8rem',

    hamburgerTextColor: '#000000',
    hamburgerFontSize: '1.6rem',
    hamburgerFontWeight: '500',
    hamburgerLineHeight: '1.9rem',
    hamburgerBackground: '#ffffff',
    hamburgerBoxShadow: '0 0 10px rgba(150, 150, 150, 0.5)',
    hamburgerSignupTextColor: '#ffffff',
    hamburgerSignupBackground: '#000000',

    overlayBackground: 'rgba(96, 96, 96, 0.5)',
    overlayFilter: 'none'
  },

  arrowButton: {
    width: '16px',
    height: '12px',
    textColor: '#a3b8c2',
    hoverColor: '#000000',
    secondaryHoverColor: '#ffffff'
  },

  button: {
    loadingType: 'TailSpin',
    primary: {
      height: '5rem',
      background: '#000000',
      border: 'none',
      borderRadius: '6px',
      boxShadow: '0 0 25px rgba(46, 53, 56, 0.1)',
      color: '#ffffff',
      fontSize: '1.6rem',
      fontWeight: '600',
      iconColor: '#ffffff',
      iconSpacing: '1.4rem',
      padding: '0 1rem',

      hoverBackground: '#404040',
      hoverBorder: 'none',
      hoverBoxShadow: '0 0 25px rgba(46, 53, 56, 0.1)',
      hoverColor: '#ffffff',

      disabledColor: '#ffffff',
      disabledBackground: 'rgba(163, 184, 194, 0.5)',
      disabledBorder: 'none',
      disabledBoxShadow: '0 1px 10px rgba(0, 0, 0, 0.08)'
    },
    secondary: {
      height: '5rem',
      background: '#ffffff',
      border: 'none',
      borderRadius: '6px',
      boxShadow: 'none',
      color: '#a3b8c2',
      fontSize: '1.6rem',
      fontWeight: '600',
      iconColor: '#a3b8c2',
      iconSpacing: '1.4rem',
      padding: '0 1rem',

      hoverBackground: '#ffffff',
      hoverBorder: 'none',
      hoverBoxShadow: '0 0 25px rgba(46, 53, 56, 0.1)',
      hoverColor: '#000000',

      disabledColor: '#ffffff',
      disabledBackground: 'rgba(163, 184, 194, 0.5)',
      disabledBorder: 'none',
      disabledBoxShadow: '0 1px 10px rgba(0, 0, 0, 0.08)'
    },
    alternative: {
      height: '5rem',
      background: '#4984ef',
      border: 'none',
      borderRadius: '6px',
      boxShadow: 'none',
      color: '#ffffff',
      fontSize: '1.6rem',
      fontWeight: '600',
      iconColor: '#ffffff',
      iconSpacing: '1.4rem',
      padding: '0 1rem',

      hoverBackground: '#4984ef',
      hoverBorder: 'none',
      hoverBoxShadow: 'none',
      hoverColor: '#ffffff',

      disabledColor: '#ffffff',
      disabledBackground: 'rgba(163, 184, 194, 0.5)',
      disabledBorder: 'none',
      disabledBoxShadow: '0 1px 10px rgba(0, 0, 0, 0.08)'
    },
    'round-primary': {
      height: '4rem',
      background: '#ffffff',
      border: 'none',
      borderRadius: '3px',
      boxShadow: '0 1px 10px rgba(0, 0, 0, 0.08)',
      color: '#000000',
      fontSize: '1.4rem',
      fontWeight: '600',
      iconColor: '#ff3131',
      iconHeight: '1.4rem',
      iconSpacing: '6px',
      padding: '0 2.5rem',

      hoverBackground: '#ffffff',
      hoverBorder: 'none',
      hoverBoxShadow: '0 2px 15px rgba(0, 0, 0, 0.15)',
      hoverColor: '#000000',

      disabledColor: '#ffffff',
      disabledBackground: 'rgba(163, 184, 194, 0.5)',
      disabledBorder: 'none',
      disabledBoxShadow: 'none'
    },
    'round-secondary': {
      height: '4rem',
      background: '#1a1a1a',
      border: 'none',
      borderRadius: '3px',
      boxShadow: 'none',
      color: '#828282',
      fontSize: '1.4rem',
      fontWeight: '600',
      iconColor: '#4f4f4f',
      iconHeight: '1.4rem',
      iconSpacing: '6px',
      padding: '0 2.5rem',

      hoverBackground: '#1a1a1a',
      hoverBorder: 'none',
      hoverBoxShadow: 'none',
      hoverColor: '#ffffff',

      disabledColor: '#ffffff',
      disabledBackground: 'rgba(163, 184, 194, 0.5)',
      disabledBorder: 'none',
      disabledBoxShadow: 'none'
    },
    delete: {
      height: '4rem',
      background: 'rgba(255, 49, 49, 0.7)',
      border: 'none',
      borderRadius: '3px',
      boxShadow: 'none',
      color: '#ffffff',
      fontSize: '1.4rem',
      fontWeight: '600',
      iconColor: '#ffffff',
      iconHeight: '1.4rem',
      iconSpacing: '6px',
      padding: '0 2.5rem',

      hoverBackground: 'rgba(255, 49, 49, 0.7)',
      hoverBorder: 'none',
      hoverBoxShadow: '0 2px 15px rgba(0, 0, 0, 0.15)',
      hoverColor: '#ffffff',

      disabledColor: '#ffffff',
      disabledBackground: 'rgba(163, 184, 194, 0.5)',
      disabledBorder: 'none',
      disabledBoxShadow: 'none'
    }
  },

  checkbox: {
    checkedIconBackgroundColor: 'black',
    checkedIconBorderColor: 'black',
    iconBackground: 'white',
    iconBorder: '1px solid #dadada',
    iconBorderRadius: '0.3rem',
    iconSize: '1.6rem',
    fontSize: '1.6rem',
    fontStyle: 'normal',
    fontWeight: '500',
    textColor: '#a3b8c2'
  },

  contentWrapper: {
    backgroundColor: '#ffffff',
    boxShadow: '0 1px 10px rgba(0, 0, 0, 0.08)',
    textColor: '#000000',
    staticColor: '#a3b8c2',
    gridRowGap: '0',
    gridRowGap2: '0',
    gridRowGap3: '0'
  },

  datepicker: {
    container: {
      backgroundColor: '#ffffff',
      borderColor: 'rgba(163, 184, 194, 0.15)', //rgba(163, 184, 194, 0.3)
      borderRadius: '6px',
      boxShadow: '0 0 25px rgba(46, 53, 56, 0.1)',
      fontSize: '1.2rem',
      padding: '0 0 1rem'
    },
    dayName: {
      fontSize: '1.2rem',
      fontWeight: '600',
      lineHeight: 'normal',
      textColor: '#000000',
      marginTop: '1rem',
      marginBottom: '0.5rem'
    },
    header: {
      arrowSize: '1.2px',
      arrowTop: '1rem',
      arrowLeftRight: '1rem',
      backgroundColor: 'rgba(163, 184, 194, 0.1)',
      borderColor: 'rgba(163, 184, 194, 0.15)',
      fontSize: '1.4rem',
      fontWeight: '500',
      lineHeight: '3rem',
      textColor: '#000000',
      padding: '1rem 1rem 0'
    },
    cells: {
      border: '1px solid transparent',
      borderRadius: '4px',
      height: '3rem',
      width: '3rem',
      fontSize: '1.4rem',
      fontWeight: 'normal',
      textColor: '#000000',
      outsideColor: '#939ea7',
      margin: '2px 4px',
      hoverBackground: 'transparent',
      hoverBorder: '1px solid #85a0ad',
      hoverTextColor: '#000000',
      selectedBackground: '#000000',
      selectedBorder: '1px solid #000000',
      selectedColor: '#ffffff'
    }
  },

  dataTable: {
    body: {
      backgroundColor: '#ffffff',
      border: '1px solid #e5e5e5',
      borderColor: '#e5e5e5',
      textColor: '#000000',
      fontSize: '1.6rem',
      fontWeight: '500',
      dropShadow: '0 1px 10px rgba(0, 0, 0, 0.08)',
      infoLineColor: '#939ea7'
    },
    head: {
      background: 'rgba(163, 184, 194, 0.3)',
      border: '2px solid #e5e5e5',
      textColor: '#a4a6b0',
      fontSize: '1.2rem',
      fontWeight: 'bold'
    },
    hover: {
      backgroundColor: 'rgba(163, 184, 194, 0.1);',
      border: '1px solid #f4f6f6',
      textColor: '#000000'
    }
  },

  dropTarget: {
    background: 'rgba(255, 255, 255, 0.9)',
    border: '2px solid #828282',
    borderRadius: '3px',
    textColor: '#000000',
    fontSize: '1.6rem',
    fontWeight: 'normal'
  },

  errorDisplay: {
    borderColor: 'transparent',
    borderBottomColor: '#ff3131',
    textColor: '#ff3131',
    fontSize: '1.4rem',
    fontWeight: 'normal',
    lineHeight: '1.7rem',
    margin: '1rem 0'
  },

  finalMessage: {
    fontSize: '1.6rem',
    fontWeight: '500',
    textColor: '#000000',
    infoTextColor: '#2f80ed',
    dangerTextColor: '#ee2b2b',
    successTextColor: '#59b300',
    padding: '0 2.5rem 4.5rem',
    contentPadding: '2.5rem 0 0'
  },

  gridTable: {
    border: '1px solid #e5e5e5',
    fontSize: '1.4rem',
    fontSize2: '1.6rem',
    fontWeight: '500',
    lineHeight: '1.7rem',
    lineHeight2: '2rem',
    labelColor: '#85a0ad',
    valueColor: '#000000',
    negativeColor: '#ee2b2b',
    positiveColor: '#59b300',
    rowPadding: '1rem 0',
    totalFontSize: '2rem',
    totalFontSize2: '2.4rem',
    totalFontWeight: 'bold',
    totalLineHeight: '2.4rem',
    totalLineHeight2: '3rem'
  },

  input: {
    label: {
      textColor: '#85a0ad',
      fontSize: '1rem',
      fontWeight: '400',
      lineHeight: '1.2rem',
      textTransform: 'none',
      marginBottom: '5px',
      marginLeft: '2rem',
      asteriskColor: '#ff3131',
      asteriskMargin: '3px',
      asteriskPosition: 'right',
      paddingWithIcon: '2.5rem',
      paddingRequired: '1.7rem'
    },
    error: {
      textColor: '#ff3131',
      padding: '0',
      marginTop: '1rem'
    },
    wrapper: {
      marginBottom: '2rem'
    },
    container: {
      textColor: '#000000',
      fontSize: '1.4rem',
      fontWeight: '400',
      lineHeight: '1.7rem',
      background: '#f5f6f8',
      border: '1px solid transparent',
      borderWidth: '1px',
      borderRadius: '6px',
      padding: '0 2rem',
      iconColor: '#85a0ad',
      errorBorderColor: 'rgba(163, 184, 194, 0.3)',
      errorBorderWidth: '1px',
      focusedBorderColor: 'rgba(73, 132, 239, 0.7)',
      focusedBorderWidth: '2px'
    },
    placeholder: {
      textColor: '#85a0ad',
      fontSize: '1.4rem',
      fontWeight: '400',
      lineHeight: '1.7rem'
    },
    dropDown: {
      controlBorder: 'none',
      controlBorderRadius: '0',
      controlPadding: '0',
      indicatorColor: '#85a0ad',
      menuBackground: 'hsl(0, 0%, 100%)',
      menuBorder: 'none',
      menuBorderRadius: '4px',
      menuMargin: '8px 0',
      menuPadding: '0',
      openedBackground: 'transparent',
      openedBorder: 'none',
      openedBorderRadius: '0',
      openedColor: '#000000',
      optionBorderRadius: '0',
      optionColor: '#000000',
      optionFontSize: '1.4rem',
      optionFontWeight: '400',
      optionLineHeight: '1.7rem',
      optionMargin: '0',
      optionPadding: '1rem',
      optionBackground: '#ffffff',
      focusedOptionColor: '#ffffff',
      focusedOptionBackground: '#1a1a1a',
      buttonColor: '#000000',
      buttonTextAlign: 'left',
      buttonBorderRadius: '0',
      buttonBackground: '#ffffff',
      buttonMargin: '0',
      buttonWidth: 'auto'
    },
    settings: {
      height: '4.6rem',
      singleSelect: false,
      autofillBackground: '#ffffff',
      dropDownArrowColor: '#85A0AD',
      textareaMargin: '0',
      textareaPadding: '1rem 0',
      hideLabelWhenEmpty: false,
      showPasswordWithText: false
    }
  },

  loader: {
    spinnerColor: '#828282',
    spinnerSize: 55,
    spinnerType: 'TailSpin',
    loaderColor: '#000000',
    loaderSize: 75
  },

  noElementsMessage: {
    background: 'transparent',
    border: 'none',
    borderRadius: '0',
    boxShadow: 'none',
    minHeight: '15rem',
    padding: '2rem',
    textColor: '#a3b8c2',
    fontSize: '1.6rem',
    fontWeight: 'normal',
    lineHeight: '2.4rem'
  },

  progressBar: {
    background: '#d9d9d9',
    colorGreen: 'rgba(68, 204, 0, 0.5)',
    colorRed: 'rgba(255, 49, 49, 0.5)'
  },

  pagination: {
    marginPages: 2,
    minPagesVisible: 0,
    noArrowButtons: true,
    showPageLabel: true,
    fontSize: '1.2rem',
    fontWeight: '500',
    lineHeight: '1.5rem',
    textColor: '#85a0ad',
    backgroundColor: 'transparent',
    buttonBorderColor: 'rgba(163, 184, 194, 0.3)',
    buttonRadius: '4px',
    buttonMargin: '1rem',
    buttonSize: '3rem',
    buttonTextColor: '#85a0ad',
    buttonHoverBorderColor: 'rgba(163, 184, 194, 0.3)',
    buttonHoverTextColor: '#000000',
    buttonActiveBackground: '#000000',
    buttonActiveBorderRadius: '4px',
    buttonActiveBoxShadow: 'none',
    buttonActiveTextColor: '#ffffff'
  },

  radioButton: {
    iconSize: '2.4rem',
    iconSpacing: '1.6rem',
    fontSize: '1.6rem',
    fontStyle: 'normal',
    fontWeight: '500',
    lineHeight: '2rem',
    textColor: '#828282',
    loadingColor: '#828282',
    loadingSize: '30'
  },

  switchInput: {
    width: '4.6rem',
    height: '2.6rem',
    border: 'none',
    borderRadius: '2.3rem',

    color: '#ffffff',
    fontSize: '1.6rem',
    fontStyle: 'normal',
    fontWeight: 'normal',
    lineHeight: '120%',

    ballSize: '2.2rem',
    ballBackground: '#ffffff',
    ballBoxShadow: '0 2px 2px rgba(0, 0, 0, 0.24)',

    checkedBackground: '#000000',
    uncheckedBackground: '#f4f6f6',

    uncheckedBallColor: '#ffffff',
    uncheckedBorder: 'none',

    disabledColor: '#4f4f4f',
    disabledBallColor: '#ffffff',
    disabledBorder: 'transparent',
    disabledBackground: '#000000',
    disabledOpacity: '0.5'
  },

  backButtonIcon,
  backButtonIconHover,
  backButtonIconSecondary: backButtonIcon,
  backButtonIconSecondaryHover,

  checkedIcon,
  radioSelected,
  radioUnselected
}

export default portalTheme
