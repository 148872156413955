import styled from 'styled-components'

import { translateMonthName } from 'mmfintech-commons'

export const ActivityDateLine = ({ datum }) => {
  const dt = new Date(datum)
  const day = dt.getDate()
  const year = dt.getFullYear()
  const month = translateMonthName(dt.getMonth())

  return (
    !isNaN(dt.getDate()) && (
      <DateSectionWrapper className='date-section'>
        <span className='grid-line left' />
        <span className='date-wrap'>
          <span className='day'>{day}</span>
          <span className='year'>
            {month}, {year}
          </span>
        </span>
        <span className='grid-line right' />
      </DateSectionWrapper>
    )
  )
}

const DateSectionWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr auto 1fr;

  width: 100%;
  margin: 3rem 0;
  padding: 0 3rem;

  @media (max-width: 1400px) {
    padding: 0;
    margin: 1rem 0;
  }

  .grid-line {
    width: 100%;
    height: 50%;
    border-bottom: 1px solid #000000;
  }

  .date-wrap {
    display: flex;
    flex-flow: column nowrap;
    align-items: center;

    margin: 0 1.6rem;

    color: #000000;
    font-size: 1.4rem;
    font-style: normal;
    font-weight: normal;
    line-height: 1.7rem;
    text-transform: capitalize;

    .day {
      font-size: 3.6rem;
      font-weight: bold;
      line-height: 4.4rem;
    }
  }
`
